<template>
    <div class="mx-1 w-full border-b border-font-gray flex px-2" v-if="row">
        <div class="text-font-gray">
            Win <small>({{ row.total | numberFormat() }})</small>
        </div>
        <div class="ml-auto font-semibold">
            {{ row.sales | reduceBigNumbers(2) }}€
        </div>
        <div class="ml-4 text-success">
            {{ row.percentage | numberFormat(2) }}%
        </div>
    </div>
</template>

<script>
    export default {
        name: "Summary",
        props: ['row']
    }
</script>